import React, { Component, useContext } from "react";

import { ReactComponent as WorkFromOfficeLogo } from "../../../assets/svg/marker.svg";
import {
  ITheme,
  mergeStyleSets,
  getTheme,
  getFocusStyle,
  mergeStyles,
} from "@fluentui/react/lib/Styling";
import { FloorsClient } from "../../../infrastructure/api/FloorsClient";
import {
  getOverlayCenterLatLng,
  getMapBounds,
  CustomPoint,
} from "../../../helpers/imageHelper";
import {
  Shimmer,
  DefaultButton,
  Modal,
  DialogType,
  Dialog,
  DialogContent,
} from "@fluentui/react";
import {
  MapContainer,
  TileLayer,
  useMapEvents,
  MapConsumer,
  Marker,
  ImageOverlay,
  SVGOverlay,
  CircleMarker,
  Circle,
  Popup,
  Tooltip,
} from "react-leaflet";
import * as L from "leaflet";
import moment from "moment-timezone";
import Moment from "react-moment";
import { WorkDaysClient } from "../../../infrastructure/api/WorkDaysClient";
import { CheckInsClient } from "../../../infrastructure/api/CheckInsClient";
import { DurationSelector } from "../../Selectors/DurationSelector";
import { HourSelector } from "../../Selectors/HourSelector";
import _ from "lodash";
import "./FloorPlanMobile.css";
import { BookingFooterPanel } from "../BookingFooterPanel/BookingFooterPanel";
// import Legend from "./Legend.jsx";

export class FloorPlanMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photoUrl: "",
      floor: {},
      bounds: null,
      initialPosition: null,
      isBookingDialogOpen: false,
      selectedData: {},
      map: null,
      hourValue: {},
      hour: "",
      duration: 480,
      durationValue: { id: 480, name: "8 hours" },
      floorId: null,
      isReserved: false,
      isNotBookable: false,
      isBlocked: false,
    };
  }

  componentDidMount() {
    this.update();
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.floorId !== this.state.floorId) {
      this.setState({ floorId: nextProps.floorId }, this.update);
    }
  }

  async update() {
    const { floorId } = this.state;
    // this.setState({ loading: true });
    if (!floorId) return;

    var response = await FloorsClient.Get(floorId);

    if (response.successful) {
      var height = 0;
      var width = 0;
      if (response.data.blobPhotoDetails) {
        height = 432;
        width = response.data?.width > response.data?.height ? 576 : 250;
      }
      // setBounds([height, width])
      var overlayCenterLatlng = getOverlayCenterLatLng(
        response.data?.width,
        response.data?.height
      );
      var bounds = getMapBounds(response.data?.width, response.data?.height);

      this.setState({
        photoUrl: `${response.data.blobPhoto}?${new Date().getTime()}`,
        floor: response.data,
        bounds: bounds,
        initialPosition: overlayCenterLatlng,
        loading: false,
      });
    }
  }

  render() {
    const { bounds, initialPosition, photoUrl, loading } = this.state;
    const { assets, teamMemberIds, showTeamMembers } = this.props;

    var onSiteColor = localStorage.getItem("theme_OnSite");
    var homeColor = localStorage.getItem("theme_Home");
    var noResponseColor = localStorage.getItem("theme_NoResponse");
    var notWorkingColor = localStorage.getItem("theme_NotWorking");
    var whatsOnHolidayColor = localStorage.getItem("theme_WhatsOnHoliday");
    var whatsOnEventColor = localStorage.getItem("theme_WhatsOnEvent");
    var teamHighlightColor = localStorage.getItem("theme_HighlightTeam");
    var availableColor = localStorage.getItem("theme_Available");
    var reservedColor = localStorage.getItem("theme_Reserved");
    var inUseColor = localStorage.getItem("theme_InUse");
    var bookedColor = localStorage.getItem("theme_Booked");
    var itemColor = localStorage.getItem("theme_Item");

    var userId = localStorage.getItem("userId");

    const PopOverDetails = ({ item, hideLabel }) => {
      const { refreshAssets } = this.props;
      const currentUserId = localStorage.getItem("userId");
      const filteredAllocations =
        item.allocations && item.allocations.length > 0
          ? item.allocations.filter((i) => i.description !== "Reserved")
          : [];

      return (
        <>
          {!hideLabel && (
            <span style={{ fontSize: "14px" }}>{item.assetMapLabel}</span>
          )}
          {item.checkIns.length > 0 &&
            item.checkIns.map((checkin) => (
              <div
                style={{
                  fontSize: "12px",
                }}
              >
                {checkin.user?.name} {checkin.user?.surname}{" "}
                <Moment utc local format="h:mm A">
                  {moment(checkin.checkInDate)}
                  {/* {moment(checkin.checkInDate).add(5, "h")} */}
                </Moment>{" "}
                -{" "}
                <Moment utc local format="h:mm A">
                  {moment(checkin.checkOutDue)}
                  {/* {moment(checkin.checkOutDue).add(5, "h")} */}
                </Moment>
                {/* {checkin.userId === currentUserId && (
                  <span
                    style={{
                      marginLeft: "5px",
                      color: "red",
                      cursor: "pointer",
                    }}
                    onClick={async () => {
                      await CheckInsClient.ReleaseCheckIn(item.checkIns[0]?.id);
                      refreshAssets();
                    }}
                  >
                    X
                  </span>
                )} */}
              </div>
            ))}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <div></div>
            <DefaultButton
              text="Book"
              allowDisabledFocus
              style={{
                borderRadius: "5px",
                fontSize: "12px",
                height: "25px",
              }}
              onClick={() => {
                this.setState({ selectedData: item }, () =>
                  this.setState({ isBookingDialogOpen: true })
                );
              }}
            ></DefaultButton>
          </div>
          {filteredAllocations && filteredAllocations.length > 0 && (
            <div style={{ display: "flex", marginTop: "10px" }}>
              {filteredAllocations.map((allocation) => (
                <div
                  style={{
                    fontSize: "10px",
                    color: "white",
                    borderRadius: "5px",
                    background: allocation.color,
                    padding: "3px",
                    marginBottom: "5px",
                    marginRight: "3px",
                  }}
                >
                  {allocation.name}
                </div>
              ))}
            </div>
          )}
        </>
      );
    };

    const BookingModal = () => {
      const item = this.state.selectedData;
      const { refreshAssets } = this.props;

      const { date } = this.props;

      return (
        <BookingFooterPanel
          item={item}
          date={date}
          onDismissRefresh={() => {
            this.setState({
              isBookingDialogOpen: false,
              isReserved: false,
              isNotBookable: false,
              isBlocked: false,
            });
            refreshAssets();
          }}
          onDismiss={() =>
            this.setState({
              isBookingDialogOpen: false,
              isReserved: false,
              isNotBookable: false,
              isBlocked: false,
            })
          }
          show={this.state.isBookingDialogOpen}
          selectedDays={[date]}
          loading={this.state.loading}
          isReserved={this.state.isReserved}
          isBlocked={this.state.isBlocked}
          isNotBookable={this.state.isNotBookable}
        />
      );
    };

    return (
      <>
        {loading && (
          <div style={{ width: "100%", margin: "20px 0px" }}>
            <Shimmer style={{ padding: "5px" }} />
            <Shimmer style={{ padding: "5px" }} width="90%" />
            <Shimmer style={{ padding: "5px" }} width="80%" />
            <Shimmer style={{ padding: "5px" }} width="70%" />
            <Shimmer style={{ padding: "5px" }} width="60%" />
            <Shimmer style={{ padding: "5px" }} width="50%" />
            <Shimmer style={{ padding: "5px" }} width="40%" />
          </div>
        )}

        {!loading && bounds && (
          <>
            <div
              style={{
                width: "100%",
                height: "calc(100vh - 60px)",
                margin: "20px 0",
                background: "white",
                position: "relative",
              }}
            >
              <MapContainer
                center={initialPosition}
                zoom={2}
                // maxBoundsViscosity={1}
                crs={L.CRS.Simple}
                doubleClickZoom={false}
                style={{
                  width: "100%",
                  height: "calc(100vh - 60px)",
                  // cursor: "pointer",
                  background: "white",
                }}
                zoomControl={false}
                attributionControl={false}
              >
                <ImageOverlay url={photoUrl} bounds={bounds} />
                {assets &&
                  assets.map((item) => {
                    const { date } = this.props;
                    var isCurrent = false;
                    var isReserved = false;
                    var isTeamMember = false;
                    var isBlocked = false;
                    if (item.blockStartDate && item.blockEndDate) {
                      var compareDate = moment(date).format("YYYY-MM-DD");
                      var startDate = moment(item.blockStartDate).format(
                        "YYYY-MM-DD"
                      );
                      var endDate = moment(item.blockEndDate).format(
                        "YYYY-MM-DD"
                      );

                      var isBetween =
                        moment(compareDate).isBetween(
                          startDate,
                          endDate,
                          "day"
                        ) ||
                        moment(compareDate).isSame(startDate, "day") ||
                        moment(compareDate).isSame(endDate, "day");
                      if (isBetween) isBlocked = true;
                    }

                    const filteredAllocations =
                      item.allocations && item.allocations.length > 0
                        ? item.allocations.filter(
                            (i) => i.description !== "Reserved"
                          )
                        : [];

                    if (item.checkIns.length > 0) {
                      var start = moment(item.checkIns[0].checkInDate).format(
                        "hh:mm A"
                      );
                      var end = moment(item.checkIns[0].checkOutDue).format(
                        "hh:mm A"
                      );

                      if (moment().isBetween(start, end)) {
                        isCurrent = true;
                      }

                      if (showTeamMembers) {
                        var checkInUserIds = item.checkIns.map((x) => x.userId);
                        var match = _.intersection(
                          teamMemberIds,
                          checkInUserIds
                        );

                        if (match && match.length > 0) {
                          isTeamMember = true;
                        }
                      }
                    }

                    if (item.reservedUserId) {
                      isReserved = true;
                    }

                    const reservedAllocation = item.allocations.filter(
                      (i) => i.description === "Reserved"
                    )[0];

                    if (!reservedAllocation) isReserved = false;

                    if (isBlocked) {
                      return (
                        <Circle
                          center={[item.latitude, item.longitude]}
                          radius={0.3}
                          pathOptions={{
                            color: "#EF6D6C",
                            weight: 1,
                          }}
                          fillOpacity={1}
                          eventHandlers={{
                            click: () => {
                              this.setState(
                                { selectedData: item, isBlocked: true },
                                () =>
                                  this.setState({ isBookingDialogOpen: true })
                              );
                            },
                          }}
                        ></Circle>
                      );
                    }

                    if (item.latitude && item.longitude) {
                      // if (isTeamMember) {
                      //   return (
                      //     <Circle
                      //       center={[item.latitude, item.longitude]}
                      //       radius={0.3}
                      //       pathOptions={{
                      //         color: teamHighlightColor,
                      //         weight: 1,
                      //       }}
                      //       fillOpacity={1}
                      //     >
                      //       <Popup>
                      //         <PopOverDetails item={item} />
                      //       </Popup>
                      //     </Circle>
                      //   );
                      // }

                      if (isReserved && item.checkIns.length <= 0) {
                        return (
                          <Circle
                            center={[item.latitude, item.longitude]}
                            radius={0.3}
                            pathOptions={{
                              color: reservedColor,
                              weight: 1,
                            }}
                            fillOpacity={1}
                            eventHandlers={{
                              click: () => {
                                if (item.reservedUserId == userId)
                                  this.setState({ selectedData: item }, () =>
                                    this.setState({ isBookingDialogOpen: true })
                                  );
                                else {
                                  this.setState(
                                    { selectedData: item, isReserved: true },
                                    () =>
                                      this.setState({
                                        isBookingDialogOpen: true,
                                      })
                                  );
                                }
                              },
                            }}
                          ></Circle>
                        );
                      }

                      if (
                        item.assetType?.isBookable &&
                        item.checkIns.length <= 0
                      ) {
                        return (
                          <Circle
                            center={[item.latitude, item.longitude]}
                            radius={0.3}
                            pathOptions={{ color: availableColor, weight: 1 }}
                            fillOpacity={1}
                            eventHandlers={{
                              click: () => {
                                this.setState({ selectedData: item }, () =>
                                  this.setState({ isBookingDialogOpen: true })
                                );
                              },
                            }}
                          ></Circle>
                        );
                      }

                      if (
                        item.assetType?.isBookable &&
                        item.checkIns.length > 0
                      ) {
                        return (
                          <Circle
                            center={[item.latitude, item.longitude]}
                            radius={0.3}
                            pathOptions={{
                              color: isCurrent ? inUseColor : bookedColor,
                              weight: 1,
                            }}
                            fillOpacity={1}
                            eventHandlers={{
                              click: () => {
                                this.setState({ selectedData: item }, () =>
                                  this.setState({ isBookingDialogOpen: true })
                                );
                              },
                            }}
                          ></Circle>
                        );
                      }

                      if (!item.assetType?.isBookable) {
                        return (
                          <Circle
                            center={[item.latitude, item.longitude]}
                            radius={0.3}
                            pathOptions={{
                              color: itemColor,
                              weight: 1,
                            }}
                            fillOpacity={1}
                            eventHandlers={{
                              click: () => {
                                this.setState(
                                  { selectedData: item, isNotBookable: true },
                                  () =>
                                    this.setState({ isBookingDialogOpen: true })
                                );
                              },
                            }}
                          ></Circle>
                        );
                      }
                    } else {
                      return;
                    }
                  })}
                {/* <Markers /> */}
                {/* <Legend map={this.state.map} /> */}
              </MapContainer>
            </div>
          </>
        )}

        <BookingModal />
      </>
    );
  }
}
