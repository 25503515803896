import React, { Component, useContext } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  ImageFit,
  Stack,
  Image,
  Dropdown,
  DefaultButton,
  ChoiceGroup,
  DatePicker,
  defaultDatePickerStrings,
  addDays,
  Panel,
  Dialog,
  DialogFooter,
  PrimaryButton,
  DialogType,
  DayOfWeek,
  Shimmer,
  ShimmerElementType,
  ShimmerElementsGroup,
  Label,
  Toggle,
} from "@fluentui/react";
import {
  ITheme,
  mergeStyleSets,
  getTheme,
  getFocusStyle,
  mergeStyles,
} from "@fluentui/react/lib/Styling";
import { Icon, FontIcon } from "@fluentui/react/lib/Icon";
import {
  EventsQueryParams,
  EventsClient,
} from "../../../infrastructure/api/EventsClient";

import { ReactComponent as WhatsOnLogo } from "../../../assets/svg/calendar-clock-filled.svg";
import { EventsTable } from "../../Charts/EventsTable";
import moment from "moment";

export class WhatsOnButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      currentDate: new Date(new Date().toDateString()),
      showEvents: false,
      events: [],
      userId: null,
    };
  }

  componentDidMount() {
    this.update();
  }
  async update() {
    await this.populateData();
  }

  classNames = mergeStyleSets({
    buttonShadow: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        boxShadow: "0 4px 8px 0 rgb(0 0 0 / 5%)",
        selectors: {
          "&:hover": { boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.05)" },
        },
      },
    ],
  });

  render() {
    var whatsOnEventColor = localStorage.getItem("theme_WhatsOnEvent");

    return (
      <>
        <div
          style={{
            borderRadius: "5px",
            minWidth: "150px",
            textAlign: "left",
            padding: "10px",
            display: "flex",
            cursor: "pointer",
            marginBottom: "15px",
            justifyContent: "space-between",
          }}
          className={this.classNames.buttonShadow}
          onClick={() => this.setState({ showEvents: true })}
        >
          <div
            style={{
              display: "flex",
            }}
          >
            <WhatsOnLogo
              fill={
                this.state.events.length > 0 ? whatsOnEventColor : "#BFBFBF"
              }
              stroke={
                this.state.events.length > 0 ? whatsOnEventColor : "#BFBFBF"
              }
              height="20"
              width="20"
            />
            <div style={{ paddingLeft: "10px" }}>What's On</div>
          </div>
          <div style={{ color: "gray", fontWeight: "bolder" }}>
            {this.state.events.length}
          </div>
        </div>
        {this.renderWhatsOnPanel()}
      </>
    );
  }

  async populateData() {
    this.setState({ loading: true });

    const { selectedTeam, date } = this.props;

    var eventParams = new EventsQueryParams();
    if (selectedTeam?.id) eventParams.WithTeamIds([selectedTeam.id]);

    var start = date;
    eventParams.WithStart(start);
    eventParams.WithEnd(moment(start).add(1, "d").toISOString());

    var events = await EventsClient.List(eventParams);
    this.setState({
      events: events.data.data,
      loading: false,
      initialized: true,
    });
  }

  renderWhatsOnPanel() {
    return (
      <Panel
        isLightDismiss
        onOuterClick={() => {}}
        isOpen={this.state.showEvents}
        onDismiss={() => this.setState({ showEvents: false })}
        closeButtonAriaLabel="Close"
        headerText="What's On"
        type={7}
        customWidth="500px"
      >
        <EventsTable
          events={this.state.events}
          userId={this.props.userId}
          refreshEvents={() => {
            this.populateData();
          }}
        />
      </Panel>
    );
  }
}
