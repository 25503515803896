import { ApiResponse, ListQueryParams } from "../ApiClient";
import {
  GetAPIDomain,
  GetAPIHeaders,
  GetApiHeadersFormData,
  GetAPIHeadersPatch,
  MyDayAPIHelper,
} from "../MyDayAPIHelper";

export class CheckInsQueryParams extends ListQueryParams {
  WithUserId(value: any) {
    this.WithParam("userId", value);
    return this;
  }
  WithStartDate(value: any) {
    this.WithParam("startDate", value);
    return this;
  }

  WithEndDate(value: any) {
    this.WithParam("endDate", value);
    return this;
  }
}

export class CheckInsClient {
  static async List(params: CheckInsQueryParams) {
    var query =
      (await GetAPIDomain()) + "/checkins" + params.GenerateQueryString();
    var headers = await GetAPIHeaders();

    var response = await ApiResponse.CreateFromResponse(
      await fetch(query, {
        headers: headers,
      })
    );

    if (!response.authenticated) {
      if (await MyDayAPIHelper.RefreshToken()) {
        var headers = await GetAPIHeaders();
        console.log(headers);
        return await ApiResponse.CreateFromResponse(
          await fetch(query, {
            headers: headers,
          })
        );
      }
    }

    return response;
  }

  static async ReleaseCheckIn(id: string) {
    var query = (await GetAPIDomain()) + "/checkins/release";

    var headers = await GetAPIHeaders();

    var response = await ApiResponse.CreateFromResponse(
      await fetch(query, {
        headers: headers,
        method: "post",
        body: JSON.stringify({
          id,
        }),
      })
    );

    if (!response.authenticated) {
      if (await MyDayAPIHelper.RefreshToken()) {
        var headers = await GetAPIHeaders();
        return await ApiResponse.CreateFromResponse(
          await fetch(query, {
            headers: headers,
          })
        );
      }
    }

    return response;
  }

  static async EarlyCheckIn(id: string) {
    var query = (await GetAPIDomain()) + `/checkins/${id}/early-checkin`;

    var headers = await GetAPIHeaders();

    var response = await ApiResponse.CreateFromResponse(
      await fetch(query, {
        headers: headers,
        method: "post",
      })
    );

    if (!response.authenticated) {
      if (await MyDayAPIHelper.RefreshToken()) {
        var headers = await GetAPIHeaders();
        return await ApiResponse.CreateFromResponse(
          await fetch(query, {
            headers: headers,
          })
        );
      }
    }

    return response;
  }
}
