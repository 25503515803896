import {
  Checkbox,
  ChoiceGroup,
  Panel,
  PanelType,
  getFocusStyle,
  getTheme,
  mergeStyleSets,
  Shimmer,
} from "@fluentui/react";
import React, { Component, useContext } from "react";
import moment from "moment";
import { ReactComponent as TeamLogo } from "../../assets/svg/users-alt-solid-2.svg";
import { ReactComponent as OnSiteLogo } from "../../assets/svg/building.svg";
import { ReactComponent as NotWorkingLogo } from "../../assets/svg/brightness.svg";
import { ReactComponent as HomeLogo } from "../../assets/svg/house-chimney.svg";
import { ReactComponent as BookAssetLogo } from "../../assets/svg/add.svg";
import { ReactComponent as SkillsLogo } from "../../assets/svg/circle-user.svg";
import { ReactComponent as UserAttributeLogo } from "../../assets/svg/circle-user.svg";
import { ReactComponent as EmailLogo } from "../../assets/svg/envelope.svg";
import { ReactComponent as PhoneLogo } from "../../assets/svg/phone-flip.svg";
import { ReactComponent as PinLogo } from "../../assets/svg/marker.svg";
import { ReactComponent as CalendarPickerLogo } from "../../assets/svg/calendar-day.svg";

import { ChartColors } from "../../constants/chartColors";
import {
  HomeClient,
  HomeQueryParams,
} from "../../infrastructure/api/HomeClient";
import { AssetsCard } from "../Modules/Assets/AssetsCard/AssetsCard";
import {
  SiteQueryParams,
  SitesClient,
} from "../../infrastructure/api/SitesClient";
import {
  NotWorkingReasonClient,
  NotWorkingReasonQueryParams,
} from "../../infrastructure/api/NotWorkingReasonClient";
import { WorkDaysClient } from "../../infrastructure/api/WorkDaysClient";
import { MobileCardDoughnut } from "../Charts/MobileCardDoughnut";
import {
  TeamQueryParams,
  TeamsClient,
} from "../../infrastructure/api/TeamsClient";
import { UsersClient } from "../../infrastructure/api/UsersClient";
import { StackedBarChart } from "@fluentui/react-charting";
import { DefaultPalette } from "@fluentui/react/lib/Styling";
import { BookAssetButton } from "../Common/BookAssetButton/BookAssetButton";
import { getMonday } from "../../helpers/datesHelper";
import Moment from "react-moment";
import { MobileCalendarPicker } from "../Common/MobileCalendarPicker/MobileCalendarPicker";
import { FloorPlanMobile } from "../Common/FloorPlanMobile/FloorPlanMobile";
import { UserAttributeSelector } from "../Selectors/UserAttributeSelector";
import { SiteSelector } from "../Selectors/SiteSelector";
import {
  UserAttributesClient,
  UserAttributesQueryParams,
} from "../../infrastructure/api/UserAttributesClient";
import _ from "lodash";
import { MonthlyOverview } from "../Modules/UserProfile/AccountOverview/MonthlyOverview";
import { NoticeClient } from "../../infrastructure/api/NoticeClient";
import { MobileNoticePanel } from "../Common/MobileNoticePanel/MobileNoticePanel";

export class MobileViewWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bookedAssets: [],
      myDay: null,
      teamEvent: null,
      currentDate: new Date(new Date().toDateString()),
      selectedTeam: null,
      isSitePanelOpen: false,
      isNotWorkingPanelOpen: false,
      sites: [],
      siteOptions: [],
      notWorkingReason: [],
      notWorkingReasonOptions: [],
      percentWorking: 0,
      loading: true,
      action: "",
      todaysChart: null,
      selectedReason: null,
      isTeamPanelOpen: false,
      teamOptions: [],
      siteLimit: 0,
      lineChartPoints: [],
      showUserAttributesPanel: false,
      userAttributes: [],
      filteredAttributeUsers: [],
      reRender: false,
      showNoticePanel: false,
      noticesToView: [],
    };
  }

  componentDidMount() {
    this.update();
    this.populateStaticData();
  }

  async update() {
    await this.populateData();
  }

  componentWillReceiveProps(props) {
    const { selectedTeam, currentDate } = props;

    // if (this.state.currentDate !== currentDate) {
    //   this.setState({ currentDate: currentDate }, () => {
    //     this.populateData();
    //   });
    // }
    if (selectedTeam?.id !== this.state.selectedTeam?.id) {
      this.setState(
        {
          selectedTeam: selectedTeam,
        },
        () => {
          this.populateData();
        }
      );
    }
  }

  classNames = mergeStyleSets({
    container: { width: "100%", background: "#E7F4F6" },
    cardShadow: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        boxShadow: "0 4px 8px 0 rgb(0 0 0 / 10%)",
        selectors: {
          "&:hover": { boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.1)" },
        },
      },
    ],
    headerText: { fontSize: "17px", marginBottom: "5px", fontWeight: 500 },
  });

  onClick = async (status, newSite, notWorkingReason) => {
    const { currentUser } = this.props;
    const { currentDate } = this.state;

    //open site panel if there's already a plan for a site and if there's no new site
    if (
      this.state.myDay &&
      this.state.myDay.parts[0].state == "workFromOffice" &&
      status === "workFromOffice" &&
      !newSite &&
      !notWorkingReason
    ) {
      this.setState({ isSitePanelOpen: true });
      return;
    }

    this.setState({
      loading: true,
    });

    var today = new Date(new Date().toDateString());

    var oldState = !this.state.myDay ? null : this.state.myDay.parts[0].state;

    var newAction = "";

    var myDay = !this.state.myDay
      ? {
          date: new Date(currentDate).toDateString(),
          status: "planned",
          parts: [
            {
              state: "workFromOffice",
            },
          ],
        }
      : this.state.myDay;

    if (status === "workFromOffice") {
      newAction = "workFromOffice";

      myDay.parts[0].state = "workFromOffice";
      myDay.parts[0].notWorkingReasonId = null;
      if (newSite) {
        myDay.parts[0].siteId = newSite?.id;
        this.setState({
          defaultSite: {
            id: newSite?.id,
            name: newSite?.name,
          },
        });
      } else {
        myDay.parts[0].siteId = currentUser?.site?.id;
        this.setState({
          defaultSite: {
            id: currentUser?.site?.id,
            name: currentUser?.site?.name,
          },
        });
      }
    } else if (status === "notWorking") {
      newAction = "notWorking";
      myDay.parts[0].notWorkingReasonId = notWorkingReason.id;
      myDay.parts[0].state = "notWorking";
      myDay.parts[0].siteId = null;
      this.setState({ oldState: oldState });
    } else if (status === "workFromHome") {
      newAction = "workFromHome";
      myDay.parts[0].state = "workFromHome";
      myDay.parts[0].notWorkingReasonId = null;
      myDay.parts[0].siteId = null;
    }

    if (this.state.currentDate.getTime() == today.getTime()) {
      myDay.status = "confirmed";
      myDay.confirmed = new Date();
      myDay.dates = [this.state.currentDate];
    } else {
      myDay.status = "planned";
      myDay.dates = [this.state.currentDate];
    }

    myDay.date = this.state.currentDate;

    this.setState(
      {
        myDay: myDay,
        action: newAction,
      },
      () => {
        if (status !== "notWorking")
          this.setState({
            defaultReason: null,
          });
      }
    );

    await WorkDaysClient.Create(myDay);

    this.populateData();

    if (status === "workFromOffice") {
      this.checkNotices(myDay.parts[0].siteId);
    }
  };

  async checkNotices(siteId) {
    const { currentUser } = this.props;
    var noticeResult = await NoticeClient.GetUnviewedNoticeForUserOnSite(
      currentUser?.id,
      siteId
    );

    if (noticeResult.data?.hasNoticesToView && noticeResult.data?.notices) {
      this.setState({ noticesToView: noticeResult.data?.notices }, () => {
        this.setState({ showNoticePanel: true });
      });
    }
  }

  render() {
    const { classNames } = this;
    const {
      loading,
      action,
      todaysChart,
      percentWorking,
      siteLimit,
      lineChartPoints,
    } = this.state;
    const { currentUser } = this.props;

    var onSiteColor = localStorage.getItem("theme_OnSite");
    var homeColor = localStorage.getItem("theme_Home");
    var notWorkingColor = localStorage.getItem("theme_NotWorking");

    return (
      <>
        <div className={classNames.container}>
          <div
            className={classNames.cardShadow}
            style={{
              borderRadius: "5px",
              padding: "15px 5px",
              background: "white",
            }}
          >
            <div
              style={{
                marginBottom: "15px",
                display: "flex",
                alignItems: "center",
                padding: "0px 10px",
              }}
            >
              <TeamLogo
                fill={onSiteColor}
                stroke={onSiteColor}
                height="25"
                width="25"
                style={{
                  overflow: "visible",
                  marginRight: "10px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  this.setState({ isTeamPanelOpen: true });
                }}
              />
              <div
                style={{
                  textAlign: "left",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
              >
                <b className={classNames.headerText}>
                  {this.state.selectedTeam?.name}
                </b>
                <StackedBarChart
                  data={{
                    chartData: lineChartPoints,
                  }}
                  hideLabels
                  hideTooltip
                  barHeight={5}
                  styles={{
                    chartTitle: { display: "none" },
                    chart: { margin: 0 },
                    legend: { display: "none" },
                    root: { height: "10px" },
                  }}
                />
              </div>
            </div>
            {/* {action === "notWorking" && (
              <div
                style={{
                  marginBottom: "15px",
                  display: "flex",
                  alignItems: "center",
                  padding: "0px 10px",
                }}
              >
                <NotWorkingLogo
                  fill={notWorkingColor}
                  stroke={notWorkingColor}
                  height="25"
                  width="25"
                  style={{ overflow: "visible", marginRight: "10px" }}
                />
                <b className={classNames.headerText}>
                  {this.state.selectedReason?.name}
                </b>
              </div>
            )} */}

            <div
              style={{ width: "100%", marginBottom: "23px", display: "flex" }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "65%",
                }}
              >
                {todaysChart && (
                  <div
                    style={{
                      padding: "0px 3px",
                    }}
                  >
                    <MobileCardDoughnut
                      date={new Date(todaysChart.date)}
                      pieData={todaysChart.pieChart}
                      myDay={todaysChart}
                      percentWorking={percentWorking}
                      siteLimit={siteLimit}
                    />
                  </div>
                )}

                <b
                  className={classNames.headerText}
                  style={{
                    color: action === "workFromOffice" ? "black" : "gray",
                  }}
                >
                  {this.state.selectedSite?.name &&
                  this.state.selectedSite?.name?.length > 20
                    ? `${this.state.selectedSite?.name.slice(0, 20)}...`
                    : this.state.selectedSite?.name}
                </b>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "35%",
                  justifyContent: "end",
                  paddingRight: "10px",
                }}
              >
                <MobileCalendarPicker
                  date={this.state.currentDate}
                  onChangeDate={(date) => {
                    this.setState(
                      {
                        currentDate: date,
                      },
                      () => this.populateData()
                    );
                  }}
                  labelComponent={
                    <div style={{ display: "flex" }}>
                      <CalendarPickerLogo
                        fill={onSiteColor}
                        stroke={onSiteColor}
                        height="25"
                        width="25"
                      />
                      <div
                        className={classNames.headerText}
                        style={{ marginLeft: "8px" }}
                      >
                        <Moment utc local format="MMM DD">
                          {this.state.currentDate}
                        </Moment>
                      </div>
                    </div>
                  }
                />
              </div>
            </div>

            <div
              style={{
                padding: "0px 10px",
              }}
            >
              <div
                style={{
                  borderRadius: "5px",
                  width: "100%",
                  textAlign: "left",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  height: "25px",
                }}
              >
                <span
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    this.onClick("workFromOffice");
                  }}
                >
                  <OnSiteLogo
                    fill={
                      action === "workFromOffice"
                        ? onSiteColor
                        : ChartColors.NoResponse
                    }
                    stroke={
                      action === "workFromOffice"
                        ? onSiteColor
                        : ChartColors.NoResponse
                    }
                    height="25"
                    width="25"
                  />
                </span>
                <span
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    this.onClick("workFromHome");
                  }}
                >
                  <HomeLogo
                    fill={
                      action === "workFromHome"
                        ? homeColor
                        : ChartColors.NoResponse
                    }
                    stroke={
                      action === "workFromHome"
                        ? homeColor
                        : ChartColors.NoResponse
                    }
                    height="25"
                    width="25"
                  />
                </span>
                <span
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    this.setState({ isNotWorkingPanelOpen: true });
                  }}
                >
                  <NotWorkingLogo
                    fill={
                      action === "notWorking"
                        ? notWorkingColor
                        : ChartColors.NoResponse
                    }
                    stroke={
                      action === "notWorking"
                        ? notWorkingColor
                        : ChartColors.NoResponse
                    }
                    height="25"
                    width="25"
                  />
                </span>
                {this.state.userAttributes &&
                  this.state.userAttributes.length > 0 && (
                    <span
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        this.setState({ showUserAttributesPanel: true });
                      }}
                    >
                      <SkillsLogo
                        fill={"#00838f"}
                        stroke={"#00838f"}
                        height="25"
                        width="25"
                        style={{ overflow: "visible" }}
                      />
                    </span>
                  )}
              </div>
            </div>
          </div>
          {this.state.teamEvent && (
            <div
              style={{
                borderRadius: "5px",
                width: "100%",
                height: "30px",
                backgroundColor: `${this.state.teamEvent?.color}`,
                marginTop: "15px",
                marginBottom: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className={this.classNames.buttonShadow}
            >
              <p
                style={{
                  textAlign: "center",
                  color: "white",
                }}
              >
                Collaboration Day
              </p>
            </div>
          )}
          <div style={{ width: "100%" }}>
            <AssetsCard
              todaysPlan={this.state.myDay}
              bookedAssets={this.state.bookedAssets}
              reloadData={() => {
                this.populateData();
              }}
              key={this.state.assetsKey}
              fromMobileView
            />
          </div>
          <div
            className={classNames.container}
            style={{
              width: "100%",
              marginBottom: "75px",
              marginTop: "20px",
            }}
          >
            <div
              className={classNames.cardShadow}
              style={{
                borderRadius: "5px",
                padding: "25px 5px",
                background: "white",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <MonthlyOverview
                currentUser={currentUser}
                reRender={this.state.reRender}
                fromMobile
              />
            </div>
          </div>

          <div
            style={{
              position: "fixed",
              bottom: 0,
              right: "-15px",
              display: "flex",
              justifyContent: "end",
              marginBottom: "20px",
            }}
          >
            <BookAssetButton
              fromMobileView
              teamEvent={this.state.teamEvent}
              site={this.state.todaysChart?.users[0]?.myDay?.parts[0]?.site}
              date={this.state.currentDate}
              currentWeekStartDate={getMonday(this.state.currentDate)}
              refreshPlanner={() => {
                this.populateData();
              }}
              refreshDay={() => {
                this.populateData();
              }}
              currentUser={currentUser}
              onClosePanel={() => {
                this.populateData(true);
              }}
              onChangeDate={(date) => {
                this.setState(
                  {
                    currentDate: date,
                  },
                  () => this.populateData()
                );
              }}
              loading={this.state.loading}
            />
          </div>
        </div>

        {this.renderSitePanel()}
        {this.renderNotWorkingPanel()}
        {this.renderTeamPanel()}
        {this.renderUserAttributePanel()}
        {this.renderNoticesPanel()}
      </>
    );
  }

  renderNoticesPanel() {
    return (
      <>
        <MobileNoticePanel
          notices={this.state.noticesToView}
          show={this.state.showNoticePanel}
          onClose={() => {
            this.setState({ showNoticePanel: false, noticesToView: [] });
          }}
        />
      </>
    );
  }

  filterUsers() {
    const { currentUser } = this.props;
    var selectedSiteId =
      this.state.selectedSkillSiteId ??
      this.state.todaysChart?.users[0]?.myDay?.parts[0]?.site?.id ??
      currentUser?.site?.id;
    if (
      selectedSiteId &&
      this.state.selectedAttribute &&
      this.state.selectedAttribute?.users?.length > 0
    ) {
      var hasPlans = _.filter(this.state.selectedAttribute.users, (user) => {
        return (
          user?.site?.id === selectedSiteId ||
          (user?.myDay &&
            user?.myDay?.parts.length &&
            user?.myDay?.parts[0]?.site?.id === selectedSiteId)
        );
      });
      var sorted = _.sortBy(hasPlans, [
        function (user) {
          return user?.myDay ? -1 : 1;
        },
      ]);
      sorted = sorted.map((obj) => ({ ...obj, highlight: true }));
      var userIds = sorted.map((user) => user.userId);

      var noPlans = _.filter(this.state.selectedAttribute.users, (user) => {
        return !userIds.includes(user.userId);
      });
      // var filtered = this.state.selectedAttribute.users.filter(
      //   (user) =>
      //     (this.state.siteId && user?.site?.id === this.state.siteId) ||
      //     (user?.myDay &&
      //       user?.myDay?.parts[0].state === "workFromOffice" &&
      //       user?.myDay?.parts[0].site?.id === this.state.siteId)
      // );

      this.setState({ filteredAttributeUsers: [...sorted, ...noPlans] });
    } else if (
      selectedSiteId &&
      this.state.selectedAttribute &&
      this.state.selectedAttribute.users.length <= 0
    ) {
      this.setState({ filteredAttributeUsers: [] });
    }
  }

  renderUserAttributePanel() {
    const { currentUser } = this.props;

    var selectedSiteId =
      this.state.selectedSkillSiteId ??
      this.state.todaysChart?.users[0]?.myDay?.parts[0]?.site?.id ??
      currentUser?.site?.id;
    return (
      <Panel
        isLightDismiss
        isHiddenOnDismiss={false}
        onOuterClick={() => {}}
        isOpen={this.state.showUserAttributesPanel}
        onDismiss={() => {
          this.setState({ showUserAttributesPanel: false });
        }}
        closeButtonAriaLabel="Close"
        headerText=""
        type={1}
        onOpened={() => {
          this.getUserAttributes();
        }}
      >
        {this.state.userAttributes && this.state.userAttributes.length > 0 && (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignContent: "center",
              justifyContent: "flex-start",
            }}
          >
            {this.state.userAttributes.map((attribute) => {
              return (
                <div
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    this.setState(
                      {
                        selectedAttribute: {
                          id: attribute.id,
                          name: attribute.name,
                          color: attribute.color,
                          users: attribute.users,
                        },
                      },
                      () => {
                        this.filterUsers();
                      }
                    );
                  }}
                >
                  <div
                    style={{
                      margin: "5px 10px 0px 5px",
                      textAlign: "center",
                      color: `${attribute.color}`,
                    }}
                  >
                    <UserAttributeLogo
                      fill={attribute.color}
                      stroke={attribute.color}
                      height="20"
                      width="20"
                      style={{ overflow: "visible" }}
                    />
                    <br />
                    {attribute.name}
                  </div>
                </div>
              );
            })}
          </div>
        )}
        <div style={{ marginTop: "10px" }}>
          <UserAttributeSelector
            onSelect={(attribute) => {
              this.setState({ selectedAttribute: attribute }, () => {
                this.filterUsers();
              });
            }}
            selectedAttribute={this.state.selectedAttribute}
            siteId={selectedSiteId}
            hideLabel
            getUsers
          />
        </div>
        <div style={{ marginTop: "10px" }}>
          <SiteSelector
            onSelect={async (item) => {
              this.setState(
                { selectedSkillSite: item, selectedSkillSiteId: item.id },
                () => {
                  this.filterUsers();
                }
              );
            }}
            // setOptions={async (sites) => {
            //   this.setState({ siteOptions: sites }, () => {
            //     this.filterUsers();
            //   });
            // }}
            siteId={selectedSiteId}
            hideLabel
          />
        </div>
        {this.state.attributesLoading ? (
          <div style={{ width: "100%", display: "flex" }}>
            <div style={{ width: "100%", margin: "20px 0px" }}>
              <Shimmer style={{ padding: "5px" }} />
              <Shimmer style={{ padding: "5px" }} width="90%" />
              <Shimmer style={{ padding: "5px" }} width="80%" />
              <Shimmer style={{ padding: "5px" }} width="70%" />
              <Shimmer style={{ padding: "5px" }} width="60%" />
              <Shimmer style={{ padding: "5px" }} width="50%" />
              <Shimmer style={{ padding: "5px" }} width="40%" />
            </div>
          </div>
        ) : (
          <div>
            {this.state.filteredAttributeUsers &&
              this.state.filteredAttributeUsers.map((user) => {
                var state = "";
                var isWorkingOnSite = false;

                if (user?.myDay) {
                  state = user?.myDay?.parts[0].state;

                  isWorkingOnSite =
                    user?.myDay?.parts[0]?.siteId ==
                    this.state.selectedSkillSiteId;
                }
                return (
                  <div
                    style={{
                      backgroundColor: "white",
                      borderRadius: "15px",
                      padding: "10px",
                      marginTop: "10px",
                      fontSize: "12px",
                      border: isWorkingOnSite
                        ? `2px solid ${this.state.selectedAttribute.color}`
                        : "2px solid grey",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "13px",
                        fontWeight: "bold",
                        marginBottom: "3px",
                      }}
                    >
                      {user.name} {user.surname}
                    </div>
                    <div
                      style={{
                        color: "gray",
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "3px",
                      }}
                    >
                      <EmailLogo height="15" width="15" />
                      <div style={{ marginLeft: "5px" }}>{user.email}</div>
                    </div>

                    <div
                      style={{
                        color: "gray",
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "3px",
                      }}
                    >
                      <PhoneLogo height="15" width="15" />
                      <div style={{ marginLeft: "5px" }}>
                        {user.mobile ? user.mobile : "-"}
                      </div>
                    </div>

                    <div
                      style={{
                        color: "gray",
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "3px",
                      }}
                    >
                      <PinLogo height="15" width="15" />
                      {user?.myDay && state === "workFromOffice" && (
                        <div style={{ marginLeft: "5px" }}>
                          {user?.myDay?.parts[0].site?.name}
                        </div>
                      )}
                      {user?.myDay && state === "notWorking" && (
                        <div style={{ marginLeft: "5px" }}>
                          {user?.myDay?.parts[0].notWorkingReason?.name}
                        </div>
                      )}
                      {user?.myDay && state === "workFromHome" && (
                        <div style={{ marginLeft: "5px" }}>Home</div>
                      )}
                      {!user?.myDay && (
                        <div style={{ marginLeft: "5px" }}>Unknown</div>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </Panel>
    );
  }

  renderSitePanel = () => {
    const { isSitePanelOpen, siteOptions, selectedSite } = this.state;
    return (
      <Panel
        type={PanelType.smallFluid}
        headerText="Choose Site"
        isOpen={isSitePanelOpen}
        onDismiss={() => {
          this.setState({ isSitePanelOpen: false });
        }}
        // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
        closeButtonAriaLabel="Close"
      >
        <ChoiceGroup
          defaultSelectedKey={selectedSite?.id}
          options={siteOptions}
          onChange={(event, option) => {
            var newSite = { id: option.key, name: option.text };
            this.onClick("workFromOffice", newSite);
            this.setState({ isSitePanelOpen: false });
          }}
        />
      </Panel>
    );
  };

  renderNotWorkingPanel = () => {
    const { isNotWorkingPanelOpen, notWorkingReasonOptions } = this.state;
    return (
      <Panel
        type={PanelType.smallFluid}
        headerText="Choose Not Working Reason"
        isOpen={isNotWorkingPanelOpen}
        onDismiss={() => {
          this.setState({ isNotWorkingPanelOpen: false });
        }}
        // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
        closeButtonAriaLabel="Close"
      >
        <ChoiceGroup
          options={notWorkingReasonOptions}
          onChange={(event, option) => {
            var reason = { id: option.key, name: option.text };
            this.onClick("notWorking", null, reason);
            this.setState({ isNotWorkingPanelOpen: false });
          }}
        />
      </Panel>
    );
  };

  renderTeamPanel = () => {
    const { isTeamPanelOpen, teamOptions, selectedTeam } = this.state;
    const { currentUser } = this.props;
    return (
      <Panel
        type={PanelType.smallFluid}
        headerText="Choose Team"
        isOpen={isTeamPanelOpen}
        onDismiss={() => {
          this.setState({ isTeamPanelOpen: false });
        }}
        // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
        closeButtonAriaLabel="Close"
      >
        <ChoiceGroup
          defaultSelectedKey={selectedTeam?.id}
          options={teamOptions}
          onChange={async (event, option) => {
            await UsersClient.SetTeam(currentUser.id, option.key, true, false);
            this.setState(
              {
                isTeamPanelOpen: false,
                selectedTeam: { id: option.key, name: option.text },
              },
              () => this.populateData(false)
            );
          }}
        />
      </Panel>
    );
  };

  async populateStaticData() {
    var notWorkingReasons = await NotWorkingReasonClient.List(
      new NotWorkingReasonQueryParams().Sort("name", "asc")
    );

    var sites = await SitesClient.List(
      new SiteQueryParams().WithStatus("active").Sort("name", "asc")
    );
    var teams = await TeamsClient.List(
      new TeamQueryParams().Sort("name", "asc")
    );

    var userAttributes = await UserAttributesClient.List(
      new UserAttributesQueryParams().WithIsMenu(true).WithGetUsers(true)
    );

    const siteOptions = sites.data.data.map((site) => ({
      key: site.id,
      text: site.name,
      styles: { root: { fontSize: "20px" }, input: { fontSize: "20px" } },
    }));

    const teamOptions = teams.data.data.map((team) => ({
      key: team.id,
      text: team.name,
    }));

    const notWorkingReasonOptions = notWorkingReasons.data.data.map(
      (notWorkingReason) => ({
        key: notWorkingReason.id,
        text: notWorkingReason.name,
      })
    );
    this.setState({
      sites: sites.data.data,
      siteOptions: siteOptions,
      notWorkingReasons: notWorkingReasons.data.data,
      notWorkingReasonOptions: notWorkingReasonOptions,
      teamOptions: teamOptions,
      userAttributes: userAttributes?.data?.data || [],
    });
  }

  async populateData(checkNotices = false) {
    const { currentUser } = this.props;
    const { selectedTeam, currentDate } = this.state;

    this.setState({ loading: true });

    if (currentUser && currentUser.site) {
      var siteId = currentUser.site.id;
      var siteLimit = currentUser.site.maxLimit;

      this.setState({
        siteId: siteId,
        userId: currentUser.id,
        user: currentUser,
        siteLimit: siteLimit,
      });

      if (currentUser.teams && currentUser.teams.length > 0) {
        var team = selectedTeam ? selectedTeam : currentUser.teams[0];

        if (!this.state.selectedTeam) {
          this.setState({
            teams: currentUser.teams,
            selectedTeam: {
              id: team.id,
              name: team.name,
            },
          });
        }

        //Get currentUser MyDay
        var userSummary = await HomeClient.DayView(
          new HomeQueryParams()
            .WithSiteId(siteId)
            .WithTeamId(team.id)
            .WithUserId(currentUser.id)
            .WithNumberOfDays(1)
            .WithParam("startDate", currentDate)
        );

        var bookedAssets = userSummary.data[0]?.users[0]?.myDay?.checkIns;
        var myDay = userSummary.data[0]?.users[0]?.myDay;
        var teamEvent = userSummary.data[0]?.teamEvent;

        var todaysChart = userSummary.data[0];

        this.setState(
          {
            bookedAssets: bookedAssets,
            teamEvent: teamEvent,
            myDay: myDay,
            todaysChart: todaysChart,
            reRender: !this.state.reRender,
          },
          () => {
            const { todaysChart } = this.state;

            var percentWorking = 0;
            var action =
              this.state.myDay?.status &&
              (this.state.myDay?.status === "confirmed" ||
                this.state.myDay?.status === "planned")
                ? this.state.myDay?.parts[0]?.state
                : null;

            var selectedSite = currentUser?.site;
            var siteLimit = currentUser.site.maxLimit;
            var selectedReason = null;
            var lineChartPoints = [];

            if (this.state.myDay?.parts[0]?.state == "workFromOffice") {
              selectedSite = this.state.myDay?.parts[0]?.site;
              siteLimit = this.state.myDay?.parts[0]?.site?.maxLimit;
            } else if (this.state.myDay?.parts[0]?.state == "notWorking") {
              selectedReason = this.state.myDay?.parts[0]?.notWorkingReason;
            }

            if (todaysChart.pieChart && siteLimit > 0) {
              var pieChart = todaysChart.pieChart;
              percentWorking =
                pieChart.workingOnSite > 0
                  ? Math.round((pieChart.workingOnSite / siteLimit) * 100)
                  : 0;
            } else if (todaysChart.pieChart && !siteLimit) {
              percentWorking = 100;
              siteLimit = 0;
            }

            //for line bar chart
            if (todaysChart.pieChart) {
              var onSiteColor = localStorage.getItem("theme_OnSite");
              var homeColor = localStorage.getItem("theme_Home");
              var notWorkingColor = localStorage.getItem("theme_NotWorking");
              var noResponseColor = localStorage.getItem("theme_NoResponse");

              var workingOnSite = todaysChart.pieChart.workingOnSite;
              var workingFromHome = todaysChart.pieChart.workingFromHome;
              var notWorking = todaysChart.pieChart.notWorking;
              var noResponse = todaysChart.pieChart.noResponse;
              var total = todaysChart.pieChart.total;

              lineChartPoints = [
                {
                  legend: "workingOnSite",
                  data: workingOnSite,
                  color: onSiteColor,
                  placeHolder: true,
                },
                {
                  legend: "workingFromHome",
                  data: workingFromHome,
                  color: homeColor,
                  placeHolder: true,
                },
                {
                  legend: "notWorking",
                  data: notWorking,
                  color: notWorkingColor,
                  placeHolder: true,
                },
                {
                  legend: "noResponse",
                  data: noResponse,
                  color: noResponseColor,
                  placeHolder: true,
                },
              ];
            }

            this.setState({
              action,
              loading: false,
              selectedSite: selectedSite,
              selectedReason: selectedReason,
              siteLimit: siteLimit,
              percentWorking: percentWorking,
              lineChartPoints: lineChartPoints,
            });

            if (checkNotices == true && selectedSite?.id) {
              this.checkNotices(selectedSite?.id);
            }
          }
        );
      }
    }

    this.setState({ loading: false });
  }
}
