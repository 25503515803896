import React, { Component, useContext } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  ImageFit,
  Stack,
  Image,
  Dropdown,
  DefaultButton,
  ChoiceGroup,
  DatePicker,
  defaultDatePickerStrings,
  addDays,
  Panel,
  PanelType,
  Dialog,
  DialogFooter,
  PrimaryButton,
  DialogType,
  DayOfWeek,
  Shimmer,
  ShimmerElementType,
  ShimmerElementsGroup,
  Label,
  TextField,
  ComboBox,
  SearchBox,
  IconButton,
} from "@fluentui/react";
import {
  ITheme,
  mergeStyleSets,
  getTheme,
  getFocusStyle,
  mergeStyles,
} from "@fluentui/react/lib/Styling";
import { Icon, FontIcon } from "@fluentui/react/lib/Icon";
import {
  ChevronLeft12Filled,
  ChevronRight12Filled,
} from "@fluentui/react-icons";
import {
  UserQueryParams,
  UsersClient,
} from "../infrastructure/api/UsersClient";
import { HomeClient, HomeQueryParams } from "../infrastructure/api/HomeClient";
import {
  SitesClient,
  SiteQueryParams,
} from "../infrastructure/api/SitesClient";

import { MyDayAPIHelper } from "../infrastructure/MyDayAPIHelper";

import {
  UserAttributesClient,
  UserAttributesQueryParams,
} from "../infrastructure/api/UserAttributesClient";
import { FloorsClient } from "../infrastructure/api/FloorsClient";
import {
  AssetsClient,
  AssetsQueryParams,
} from "../infrastructure/api/AssetsClient";

import Moment from "react-moment";
import { CardDoughnut } from "./Charts/CardDoughnut";
import { NavItem } from "../components/Common/NavItem";
import { ReactComponent as DashboardLogo } from "../assets/svg/apps.svg";
import { ReactComponent as PlannerLogo } from "../assets/svg/calendar.svg";
import { ReactComponent as AssetLogo } from "../assets/svg/ticket.svg";
import { ReactComponent as WhatsOnLogo } from "../assets/svg/calendar-clock.svg";
import { ReactComponent as TripleUsersLogo } from "../assets/svg/users-alt.svg";
import { ReactComponent as NoUserLogo } from "../assets/svg/user-xmark.svg";
import { ReactComponent as CheckInLogo } from "../assets/svg/grid-2.svg";
import { ReactComponent as AngleLeftLogo } from "../assets/svg/angle-left.svg";
import { ReactComponent as MapLogo } from "../assets/svg/map.svg";
import { ReactComponent as ListLogo } from "../assets/svg/list.svg";
import { ReactComponent as MenuDotsLogo } from "../assets/svg/menu-dots-vertical.svg";
import { ReactComponent as UserAttributeLogo } from "../assets/svg/circle-user.svg";
import { ReactComponent as SearchLogo } from "../assets/svg/search.svg";
import { ReactComponent as EmailLogo } from "../assets/svg/envelope.svg";
import { ReactComponent as PhoneLogo } from "../assets/svg/phone-flip.svg";
import { ReactComponent as PinLogo } from "../assets/svg/marker.svg";
import { EventsTable } from "./Charts/EventsTable.js";
import { Planner } from "../components/Pages/Planner";
import { UserProfile } from "../components/Pages/UserProfile";
import { Assets } from "../components/Pages/Assets";
import { Dashboard } from "../components/Pages/Dashboard";
import MenuItemTypes from "./Enums/MenuItemTypes";
import { AccountSettingsClient } from "../infrastructure/api/AccountSettingsClient";
import { ChartColors, ChartLabels } from "../constants/chartColors";
import { setAccountTheme } from "../helpers/accountSettingsHelper";
import { UserAttributeSelector } from "./Selectors/UserAttributeSelector";
import { SiteSelector } from "./Selectors/SiteSelector";
import { AssetTypeColumnSelector } from "./Selectors/AssetTypeColumnSelector";
import { FloorSelector } from "./Selectors/FloorSelector";
import moment from "moment-timezone";
import _ from "lodash";
import { NeighborhoodSelector } from "./Selectors/NeighborhoodSelector";
import { MobileViewWrapper } from "./MobileViewWrapper/MobileViewWrapper";

export class HomeScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      codeStatus: "",
      loading: false,
      currentDate: new Date(new Date().toDateString()),
      currentWeekStartDate: this.getMonday(new Date(new Date().toDateString())),
      isDatePickerOpen: false,
      teams: [],
      selectedTeam: null,
      userSummary: [],
      userSummaryWeekends: [],
      showWeekends: false,
      selectedMenuItem: MenuItemTypes.Dashboard,
      refreshPlanner: false,
      showPanel: false,
      attributesLoading: false,
      showAssetsFilterPanel: false,
      showFloorPlan: false,
      assetTypes: [],
      floor: {},
      showTeamMembers: false,
      reloadAssets: false,
      assetsInitialized: false,
      showSearch: false,
      searchableUsers: [],
      searchUsers: [],
      searchUserLoading: false,
      searchString: "",
      isMobile: false,
      primaryTeam: null,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    //TODO: Move this in the sign in process
    this.getUser();
    //--------------------------------------
    this.update();
    this.setFilterAssets();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    if (window.innerWidth < 1000) this.setState({ isMobile: true });
    else this.setState({ isMobile: false });
  }

  setFilterAssets() {
    var selectedFloor = localStorage.getItem("assets_selectedFloor");

    if (selectedFloor !== null) {
      this.setState({ floor: JSON.parse(selectedFloor) }, () => {
        if (this.state.floor?.plottable) this.setState({ showFloorPlan: true });
      });
    }

    var selectedSite = localStorage.getItem("assets_selectedSite");

    if (selectedSite !== null) {
      this.setState({ siteId: selectedSite.id });
    }

    var selectedAssetType = localStorage.getItem("assets_selectedAssetType");

    if (selectedAssetType !== null) {
      this.setState({ selectedAssetType: JSON.parse(selectedAssetType) }, () =>
        this.populateAssets()
      );
    }
  }

  async update() {
    await this.populateData();
  }

  async getUser() {
    var response = await UsersClient.GetCurrentUserByToken();
    const { data } = response;

    localStorage.setItem("userId", data?.id);
  }

  classNames = mergeStyleSets({
    container: {
      overflow: "auto",
      maxHeight: 450,
    },
    itemHeader: {
      backgroundColor: "#4052AB",
      color: "white",
      borderRadius: "5px 5px 0 0",
    },
    itemCell: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        minHeight: 54,
        padding: 10,
        boxSizing: "border-box",
        borderBottom: `1px solid ${getTheme().semanticColors.bodyDivider}`,

        boxShadow: "0 4px 8px 0 rgb(0 0 0 / 10%)",
        selectors: {
          "&:hover": { background: getTheme().palette.neutralLight },
          boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.1)",
        },
      },
    ],
    itemImage: {
      width: 40,
      height: 40,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    itemContent: {
      marginLeft: 10,
      marginRight: 10,
      overflow: "hidden",
      flexGrow: 1,
      display: "flex",
      justifyContent: "space-between",
    },
    itemName: [
      getTheme().fonts.Large,
      {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    ],
    panelItemName: [
      getTheme().fonts.mediumPlus,
      {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    ],
    itemIndex: {
      fontSize: getTheme().fonts.xSmall.fontSize,
      color: getTheme().palette.neutralTertiary,
      marginBottom: 10,
    },
    chevron: {
      alignSelf: "center",
      marginLeft: 10,
      color: getTheme().palette.neutralTertiary,
      fontSize: getTheme().fonts.mediumPlus.fontSize,
      flexShrink: 0,
    },
    contentClass: {
      backgroundColor: getTheme().palette.neutralLight,
      color: getTheme().palette.neutralDark,
      lineHeight: "50px",
      padding: "0 20px",
      background: "#FAFBFA",
      //   background:
      //     "linear-gradient(141deg, rgba(252,194,80,0.5) 0%, rgba(240,110,108,0.5) 50%, rgba(12,133,144,0.5) 100%)",
    },
    dropdown: { width: 300, borderRadius: "10px" },
    exampleRoot: {
      marginTop: "20px",
    },
    nameText: {
      fontWeight: "bold",
    },
    stackRoot: {
      width: "100%",
      display: "flex",
      height: "calc(100vh - 80px)",
    },
    firstStack: {
      background: "#FFFFFF",
    },
    secondStack: {
      background:
        "linear-gradient(170deg, rgba(0,0,0,0) 0%, rgba(214,237,241,1) 85%, rgba(221,244,248,1) 100%)",
      overflowY: "scroll",
    },

    UserAttrItemCell: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        minHeight: 54,
        padding: 10,
        boxSizing: "border-box",
        borderBottom: `1px solid ${getTheme().semanticColors.bodyDivider}`,
        transition: "0.3s",
        margin: "10px 0px",
        boxShadow: "0 4px 8px 0 rgb(0 0 0 / 5%)",
        selectors: {
          "&:hover": {
            background: getTheme().palette.neutralLight,
            boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.05)",
          },
        },
      },
    ],
    UserAttrCardShadow: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        boxShadow: "0 4px 8px 0 rgb(0 0 0 / 10%)",
        selectors: {
          "&:hover": { boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.1)" },
        },
      },
    ],
    UserAttrButtonShadow: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        boxShadow: "0 4px 8px 0 rgb(0 0 0 / 5%)",
        selectors: {
          "&:hover": { boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.05)" },
        },
      },
    ],
  });
  render() {
    var stackTokens = {
      childrenGap: 20,
      padding: 20,
    };
    const iconClass = mergeStyles({
      fontSize: 50,
      height: 50,
      width: 50,
      margin: "0 25px",
    });
    var teamHighlightColor = localStorage.getItem("theme_HighlightTeam");

    return (
      <>
        <div className="deskContent">
          <div className={this.classNames.contentClass}>
            <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
              <Stack
                horizontal
                tokens={{ childrenGap: 20 }}
                horizontalAlign="space-between"
                style={{ minHeight: 50 }}
                wrap
              >
                <Stack
                  horizontal
                  tokens={{ childrenGap: 20 }}
                  verticalAlign="center"
                >
                  <DatePicker
                    firstDayOfWeek={DayOfWeek.Monday}
                    id="currentDatePicker2"
                    minDate={new Date()}
                    value={this.state.currentDate}
                    onSelectDate={(date) =>
                      this.setState(
                        {
                          currentDate: date,
                          currentWeekStartDate: this.getMonday(date),
                          isDatePickerOpen: false,
                        },
                        () => this.populateData()
                      )
                    }
                    placeholder="Select a date..."
                    ariaLabel="Select a date"
                    // DatePicker uses English strings by default. For localized apps, you must override this prop.
                    strings={defaultDatePickerStrings}
                    styles={{
                      wrapper: { minWidth: "150px" },
                    }}
                    className="daysie-calendar"
                  />
                  <ChevronLeft12Filled
                    style={{
                      margin: 0,
                      cursor:
                        this.state.loading ||
                        this.state.currentDate.getTime() < new Date().getTime()
                          ? "not-allowed"
                          : "pointer",
                      paddingTop: "5px",
                      margin: "0px 5px",
                    }}
                    disabled={
                      this.state.loading ||
                      (this.state.currentDate.getTime() <
                        new Date().getTime() &&
                        !this.state.selectedMenuItem === MenuItemTypes.Assets)
                    }
                    onClick={() => {
                      if (
                        this.state.loading ||
                        this.state.currentDate.getTime() < new Date().getTime()
                      )
                        return;

                      if (
                        this.state.selectedMenuItem === MenuItemTypes.Planner
                      ) {
                        this.backwardOneWeek();
                      } else {
                        this.backwardOneDay();
                      }
                    }}
                  />
                  <ChevronRight12Filled
                    style={{
                      margin: 0,
                      cursor: this.state.loading ? "not-allowed" : "pointer",
                      paddingTop: "5px",
                    }}
                    disabled={this.state.loading}
                    onClick={() => {
                      if (
                        this.state.selectedMenuItem === MenuItemTypes.Planner
                      ) {
                        this.forwardOneWeek();
                      } else {
                        this.forwardOneDay();
                      }
                    }}
                  />
                  {(this.state.selectedMenuItem === MenuItemTypes.Planner ||
                    this.state.selectedMenuItem === MenuItemTypes.Dashboard) &&
                    this.state.teams.length > 1 && (
                      <>
                        <Dropdown
                          styles={{
                            title: {
                              borderRadius: "5px",
                            },
                            dropdownItemsWrapper: {
                              borderRadius: "5px",
                            },
                            root: {
                              paddingTop: "5px",
                            },
                          }}
                          placeholder="Select a team"
                          selectedKey={
                            this.state.selectedTeam
                              ? this.state.selectedTeam.id
                              : null
                          }
                          options={this.state.teams.map((item) => {
                            return {
                              key: item.id,
                              text: item.name,
                            };
                          })}
                          onChanged={(item) =>
                            this.setState(
                              {
                                selectedTeam: {
                                  id: item.key,
                                  name: item.text,
                                },
                              },
                              this.update
                            )
                          }
                          className={this.classNames.dropdown}
                        />

                        <DefaultButton
                          style={{ display: "none" }}
                          text="Next team"
                          onClick={() => {
                            var count = this.state.teams.length;
                            var currentIndex = this.state.teams.findIndex(
                              (item) => item.id == this.state.selectedTeam.id
                            );
                            var targetIndex = (currentIndex + 1) % count;
                            this.setState(
                              {
                                selectedTeam: {
                                  id: this.state.teams[targetIndex].id,
                                  name: this.state.teams[targetIndex].name,
                                },
                              },
                              this.populateData
                            );
                          }}
                          disabled={this.state.loading}
                        />
                      </>
                    )}
                </Stack>

                <Stack horizontal tokens={{ childrenGap: 20 }}>
                  {this.state.selectedMenuItem === MenuItemTypes.Assets && (
                    <>
                      <div
                        style={{
                          marginRight: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          this.setState({
                            showAssetsFilterPanel: true,
                          });
                        }}
                      >
                        <AngleLeftLogo
                          height="20"
                          width="20"
                          style={{ overflow: "visible" }}
                          stroke="gray"
                          fill="gray"
                        />
                      </div>

                      {this.state.showFloorPlan ? (
                        <div
                          style={{
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            if (this.state.floor?.plottable) {
                              this.setState({
                                showFloorPlan: false,
                              });
                            }
                          }}
                        >
                          <ListLogo
                            height="20"
                            width="20"
                            style={{ overflow: "visible" }}
                            stroke="gray"
                            fill="gray"
                          />
                        </div>
                      ) : (
                        <div
                          style={{
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            this.setState({
                              showFloorPlan: true,
                            });
                          }}
                        >
                          <MapLogo
                            height="20"
                            width="20"
                            style={{ overflow: "visible" }}
                            stroke="gray"
                            fill="gray"
                          />
                        </div>
                      )}
                      <div
                        style={{
                          marginRight: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          this.setState({
                            showTeamMembers: !this.state.showTeamMembers,
                          });
                        }}
                      >
                        <TripleUsersLogo
                          height="20"
                          width="20"
                          style={{ overflow: "visible" }}
                          stroke={
                            this.state.showTeamMembers
                              ? teamHighlightColor
                              : "gray"
                          }
                          fill={
                            this.state.showTeamMembers
                              ? teamHighlightColor
                              : "gray"
                          }
                        />
                      </div>
                    </>
                  )}
                  {(this.state.selectedMenuItem === MenuItemTypes.Planner ||
                    this.state.selectedMenuItem ===
                      MenuItemTypes.Dashboard) && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        margin: "10px 0",
                        lineHeight: "15px",
                        fontSize: "13px",
                      }}
                    >
                      <div
                        style={{
                          margin: "0 30px 0px 5px",
                          textAlign: "center",
                          cursor: "pointer",
                          color: `#BFBFBF`,
                        }}
                        onClick={() => {
                          this.setState({ showSearch: true });
                        }}
                      >
                        <SearchLogo
                          fill={"#BFBFBF"}
                          stroke={"#BFBFBF"}
                          height="20"
                          width="20"
                          style={{ overflow: "visible" }}
                        />
                        <br />
                        Search
                      </div>

                      {this.state.userAttributes &&
                        this.state.userAttributes.length > 0 &&
                        this.state.userAttributes.map((attribute) => {
                          return (
                            <div
                              style={{
                                marginRight: "10px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                this.setState({
                                  attributesLoading: true,
                                  showPanel: true,
                                  selectedAttribute: attribute,
                                });
                              }}
                            >
                              <div
                                style={{
                                  margin: "0 10px 0px 5px",
                                  textAlign: "center",
                                  color: `${attribute.color}`,
                                }}
                              >
                                <UserAttributeLogo
                                  fill={attribute.color}
                                  stroke={attribute.color}
                                  height="20"
                                  width="20"
                                  style={{ overflow: "visible" }}
                                />
                                <br />
                                {attribute.name}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  )}
                </Stack>
              </Stack>
            </div>
          </div>
          <div className={this.classNames.stackRoot}>
            <div
              className={this.classNames.firstStack}
              style={
                this.state.isMobile ? { width: "60px" } : { width: "200px" }
              }
            >
              <Stack
                horizontal
                tokens={stackTokens}
                horizontalAlign="space-around"
                style={{ padding: 0 }}
              >
                <Stack.Item
                  style={{
                    backgroundColor: "#F4F4F5",
                    borderRadius: "10px",
                    width: "100%",
                  }}
                >
                  <NavItem
                    isSelected={
                      this.state.selectedMenuItem === MenuItemTypes.Dashboard
                    }
                    itemType={MenuItemTypes.Dashboard}
                    label="Dashboard"
                    onClick={() => {
                      this.setState({
                        selectedMenuItem: MenuItemTypes.Dashboard,
                      });
                    }}
                    isMobile={this.state.isMobile}
                  />
                  <NavItem
                    isSelected={
                      this.state.selectedMenuItem === MenuItemTypes.Planner
                    }
                    itemType={MenuItemTypes.Planner}
                    label="Planner"
                    onClick={() => {
                      this.setState({
                        selectedMenuItem: MenuItemTypes.Planner,
                      });
                    }}
                    isMobile={this.state.isMobile}
                  />
                  <NavItem
                    isSelected={
                      this.state.selectedMenuItem === MenuItemTypes.Assets
                    }
                    itemType={MenuItemTypes.Assets}
                    label="Assets"
                    onClick={() => {
                      var showPanel = false;
                      var isInitialized = true;

                      if (
                        localStorage.getItem("assets_selectedFloor") === null &&
                        localStorage.getItem("assets_selectedSite") === null &&
                        localStorage.getItem("assets_selectedAssetType") ===
                          null
                      ) {
                        showPanel = true;
                        isInitialized = false;
                      } else {
                        this.setFilterAssets();
                      }

                      this.setState({
                        selectedMenuItem: MenuItemTypes.Assets,
                        showAssetsFilterPanel: showPanel,
                        assetsInitialized: isInitialized,
                      });
                    }}
                    isMobile={this.state.isMobile}
                  />

                  <NavItem
                    isSelected={
                      this.state.selectedMenuItem === MenuItemTypes.UserProfile
                    }
                    itemType={MenuItemTypes.UserProfile}
                    label="Settings"
                    onClick={() => {
                      this.setState({
                        selectedMenuItem: MenuItemTypes.UserProfile,
                      });
                    }}
                    isMobile={this.state.isMobile}
                  />
                </Stack.Item>
              </Stack>
            </div>
            <div
              className={this.classNames.secondStack}
              style={{ width: "100%" }}
              // style={this.state.isMobile ? { width: "94%" } : { width: "88%" }}
            >
              {this.renderContent()}
            </div>
          </div>

          {this.renderDatePicker()}
          {this.renderUserAttributePanel()}
          {this.renderAssetFilterPanel()}
          {this.renderSearchPanel()}
        </div>
        <div className="phoneContent">
          <div
            style={{
              maxWidth: "600px",
              padding: "15px",
              textAlign: "center",
              background: "#e7f4f6",
            }}
          >
            <MobileViewWrapper
              currentDate={this.state.currentDate}
              currentUser={this.state.user}
              selectedTeam={this.state.selectedTeam}
            />
          </div>
        </div>
      </>
    );
  }

  renderAssetFilterPanel() {
    return (
      <Panel
        onOuterClick={() => {}}
        onOpen={() => {}}
        isOpen={this.state.showAssetsFilterPanel}
        onDismiss={() =>
          this.setState({
            showAssetsFilterPanel: false,
          })
        }
        isLightDismiss
        closeButtonAriaLabel="Close"
        headerText={
          <div>
            <span style={{ fontSize: "18px" }}>{`Assets Filter`}</span>
          </div>
        }
        type={PanelType.smallFixedFar}
        styles={{
          content: {
            background: "white",
          },
          contentInner: {
            background: "white",
          },
          header: {
            background: "white",
          },
          navigation: {
            background: "white",
          },
          commands: {
            background: "white",
          },
        }}
      >
        <div style={{ marginTop: "20px" }}></div>
        <div style={{ minWidth: "280px" }}>
          <SiteSelector
            onSelect={async (item) => {
              var reload = false;
              if (item.id !== this.state.siteId) reload = true;

              this.setState(
                { selectedSite: item, siteId: item.id, reloadAssets: reload },
                () => {
                  // this.populateData();
                  // if (this.state.reloadAssets) this.populateAssets();
                }
              );

              localStorage.setItem("assets_selectedSite", JSON.stringify(item));
            }}
            siteId={this.state.siteId}
            hideLabel
          />
          <div style={{ marginBottom: "5px" }}></div>
          <FloorSelector
            siteId={this.state.siteId}
            onSelect={(value) => {
              var floorAssetTypes = this.state.assetTypes.filter(
                (x) => x.floorId === value.id
              );
              var reload = false;
              if (value.id !== this.state.floor?.id) reload = true;

              this.setState(
                {
                  floor: value,
                  floorAssetTypes: floorAssetTypes,
                  showFloorPlan:
                    value.plottable && this.state.showFloorPlan ? true : false,
                  reloadAssets: reload,
                },
                () => {
                  // this.populateData();

                  if (this.state.reloadAssets) this.populateAssets();
                }
              );
              localStorage.setItem(
                "assets_selectedFloor",
                JSON.stringify(value)
              );
            }}
            defaultValue={this.state.floor}
            hideLabel
          />{" "}
          <div style={{ marginBottom: "5px" }}></div>
          <NeighborhoodSelector
            siteId={this.state.siteId}
            onSelect={(value) => {
              const { id } = value;

              var reload = false;
              if (id !== this.state.neighborhoodId) reload = true;

              this.setState(
                { neighborhoodId: id, reloadAssets: reload },
                () => {
                  if (this.state.reloadAssets) this.populateAssets();
                }
              );
            }}
            onDeselect={() => {
              this.setState({ neighborhoodId: null }, () =>
                this.populateAssets()
              );
            }}
            neighborhoodId={this.state.neighborhoodId}
            hideWhenNoData
            hideLabel
          />
          <div style={{ marginBottom: "5px" }}></div>
          {this.state.attributes && this.state.attributes.length > 0 && (
            <>
              <div style={{ display: "flex", width: "100%" }}>
                <Dropdown
                  placeholder="Select Attributes"
                  selectedKey={
                    this.state.attributeId ? this.state.attributeId : null
                  }
                  options={this.state.attributes.map((item) => {
                    return {
                      key: item.id,
                      text: item.name,
                    };
                  })}
                  onChanged={(item) => {
                    this.setState({ attributeId: item.key }, () =>
                      this.populateAssets()
                    );
                  }}
                  styles={{
                    title: { borderRadius: "5px" },
                    root: { width: "100%" },
                  }}
                />
                <div style={{ marginBottom: "5px" }}></div>
                <IconButton
                  iconProps={{ iconName: "Blocked" }}
                  title="Deselect"
                  ariaLabel="Deselect"
                  styles={{ root: { color: "red" } }}
                  onClick={() => {
                    this.setState({ attributeId: null }, () =>
                      this.populateAssets()
                    );
                  }}
                />
              </div>
            </>
          )}
        </div>
        <div
          style={{
            marginTop: "20px",
          }}
        >
          <AssetTypeColumnSelector
            ignoreUserType
            onSelect={(value) => {
              var reload = false;
              if (value.id !== this.state.selectedAssetType?.id) reload = true;

              if (this.state.floor) {
                var floorAssetTypes = this.state.assetTypes.filter(
                  (x) => x.floorId === this.state.floor.id
                );

                this.setState(
                  {
                    selectedAssetType: value,
                    floorAssetTypes: floorAssetTypes,
                    reloadAssets: reload,
                    attributes: value?.attributes || [],
                    attributeId: null,
                  },
                  () => {
                    if (this.state.reloadAssets) this.populateAssets();
                  }
                );
              } else {
                this.setState(
                  {
                    selectedAssetType: value,
                    reloadAssets: reload,
                    attributes: value?.attributes || [],
                    attributeId: null,
                  },
                  () => {
                    if (this.state.reloadAssets) this.populateAssets();
                  }
                );
              }

              localStorage.setItem(
                "assets_selectedAssetType",
                JSON.stringify(value)
              );
            }}
            enabledValues={
              this.state.floorAssetTypes &&
              this.state.floorAssetTypes.length > 0
                ? this.state.floorAssetTypes?.map((x) => x.id)
                : []
            }
            isBookableOnly
            siteId={this.state.siteId}
            selectedData={this.state.selectedAssetType}
            populateDefaultAttributes={(attributes) => {
              this.setState({
                attributes: attributes || [],
              });
            }}
          />
        </div>
      </Panel>
    );
  }

  renderContent() {
    switch (this.state.selectedMenuItem) {
      case MenuItemTypes.Dashboard:
        return (
          <Dashboard
            currentDate={this.state.currentDate}
            currentWeekStartDate={this.state.currentWeekStartDate}
            currentUser={this.state.user}
            selectedTeam={this.state.selectedTeam}
            isMobile={this.state.isMobile}
          />
        );
      case MenuItemTypes.Planner:
        return (
          <Planner
            currentDate={this.state.currentDate}
            currentWeekStartDate={this.state.currentWeekStartDate}
            selectedTeam={this.state.selectedTeam}
            goToAssets={() => {
              this.setState({
                selectedMenuItem: MenuItemTypes.Assets,
              });
            }}
            currentUser={this.state.user}
            isMobile={this.state.isMobile}
          />
        );
      case MenuItemTypes.Assets:
        return (
          <Assets
            currentDate={this.state.currentDate}
            currentWeekStartDate={this.state.currentWeekStartDate}
            currentUser={this.state.user}
            showFloorPlan={this.state.showFloorPlan}
            selectedAssetType={this.state.selectedAssetType}
            column1={this.state.column1}
            column2={this.state.column2}
            column3={this.state.column3}
            column4={this.state.column4}
            fullAssetsList={this.state.fullAssetsList}
            loading={this.state.loading}
            showTeamMembers={this.state.showTeamMembers}
            floor={this.state.floor}
            reloadAssets={(softLoad) => {
              this.populateAssets(softLoad);
            }}
            assetsInitialized={this.state.assetsInitialized}
          />
        );
      case MenuItemTypes.WhatsOn:
        return <p>WhatsOn</p>;

      case MenuItemTypes.UserProfile:
        return (
          <UserProfile
            currentDate={this.state.currentDate}
            currentWeekStartDate={this.state.currentWeekStartDate}
            currentUser={this.state.user}
            reloadPhoto={async () => {
              var currentUser = await UsersClient.Get("current");

              this.setState({ user: currentUser.data });
            }}
            primaryTeam={this.state.primaryTeam}
          />
        );
    }
  }

  renderDatePicker() {
    return (
      <Dialog
        type={DialogType.normal}
        title="Choose date"
        hidden={!this.state.isDatePickerOpen}
        onDismiss={() => this.setState({ isDatePickerOpen: false })}
      >
        <DatePicker
          firstDayOfWeek={DayOfWeek.Monday}
          id="currentDatePicker2"
          minDate={new Date()}
          value={this.state.currentDate}
          onSelectDate={(date) =>
            this.setState(
              {
                currentDate: date,
                currentWeekStartDate: this.getMonday(date),
                isDatePickerOpen: false,
              },
              this.populateData
            )
          }
          placeholder="Select a date..."
          ariaLabel="Select a date"
          // DatePicker uses English strings by default. For localized apps, you must override this prop.
          strings={defaultDatePickerStrings}
        />

        <DialogFooter>
          <DefaultButton
            onClick={(ev) => {
              this.setState({ isDatePickerOpen: false });
              ev.preventDefault();
            }}
            text="Cancel"
          />
        </DialogFooter>
      </Dialog>
    );
  }

  renderUserAttributePanel() {
    const { user: currentUser } = this.state;

    return (
      <Panel
        isLightDismiss
        isHiddenOnDismiss={false}
        onOuterClick={() => {}}
        isOpen={this.state.showPanel}
        onDismiss={() => {
          this.setState({ showPanel: false });
        }}
        closeButtonAriaLabel="Close"
        headerText="Today"
        type={1}
        onOpened={() => {
            this.getUserAttributes();
            this.filterUsers();
        }}
      >
        {" "}
        <div style={{ marginTop: "10px" }}>
          <UserAttributeSelector
            onSelect={(attribute) => {
              const { site } = currentUser;

              this.setState({ selectedAttribute: attribute }, () => {
                this.filterUsers();
              });
            }}
            selectedAttribute={this.state.selectedAttribute}
            siteId={currentUser?.site?.id}
            hideLabel
            getUsers
          />
        </div>
        <div style={{ marginTop: "10px" }}>
          <SiteSelector
            onSelect={async (item) => {
              this.setState({ selectedSite: item, siteId: item.id }, () => {
                this.filterUsers();
              });
            }}
            setOptions={async (sites) => {
              this.setState({ siteOptions: sites }, () => {
                this.filterUsers();
              });
            }}
            siteId={currentUser?.site?.id}
            hideLabel
          />
        </div>
        {this.state.attributesLoading ? (
          <div style={{ width: "100%", display: "flex" }}>
            <div style={{ width: "100%", margin: "20px 0px" }}>
              <Shimmer style={{ padding: "5px" }} />
              <Shimmer style={{ padding: "5px" }} width="90%" />
              <Shimmer style={{ padding: "5px" }} width="80%" />
              <Shimmer style={{ padding: "5px" }} width="70%" />
              <Shimmer style={{ padding: "5px" }} width="60%" />
              <Shimmer style={{ padding: "5px" }} width="50%" />
              <Shimmer style={{ padding: "5px" }} width="40%" />
            </div>
          </div>
        ) : (
          <>
            {this.state.filteredUsers &&
              this.state.filteredUsers.map((user) => {
                var state = "";

                if (user?.myDay) {
                  state = user?.myDay?.parts[0].state;
                }
                return (
                  <div
                    className={this.classNames.UserAttrItemCell + " hover"}
                    style={{
                      backgroundColor: "white",
                      borderRadius: "15px",
                      padding: "10px",
                      marginTop: "10px",
                      fontSize: "12px",
                      border: user.myDay
                        ? `2px solid ${this.state.selectedAttribute.color}`
                        : "",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "13px",
                        fontWeight: "bold",
                        marginBottom: "3px",
                      }}
                    >
                      {user.name} {user.surname}
                    </div>
                    <div
                      style={{
                        color: "gray",
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "3px",
                      }}
                    >
                      <EmailLogo height="15" width="15" />
                      <div style={{ marginLeft: "5px" }}>{user.email}</div>
                    </div>

                    <div
                      style={{
                        color: "gray",
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "3px",
                      }}
                    >
                      <PhoneLogo height="15" width="15" />
                      <div style={{ marginLeft: "5px" }}>
                        {user.mobile ? user.mobile : "-"}
                      </div>
                    </div>

                    <div
                      style={{
                        color: "gray",
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "3px",
                      }}
                    >
                      <PinLogo height="15" width="15" />
                      {user?.myDay && state === "workFromOffice" && (
                        <div style={{ marginLeft: "5px" }}>
                          {user?.myDay?.parts[0].site?.name}
                        </div>
                      )}
                      {user?.myDay && state === "notWorking" && (
                        <div style={{ marginLeft: "5px" }}>
                          {user?.myDay?.parts[0].notWorkingReason?.name}
                        </div>
                      )}
                      {user?.myDay && state === "workFromHome" && (
                        <div style={{ marginLeft: "5px" }}>Home</div>
                      )}
                      {!user?.myDay && (
                        <div style={{ marginLeft: "5px" }}>Unknown</div>
                      )}
                    </div>
                  </div>
                );
              })}
          </>
        )}
      </Panel>
    );
  }

  renderSearchPanel() {
    const { user: currentUser } = this.state;

    return (
      <Panel
        isLightDismiss
        isHiddenOnDismiss={false}
        onOuterClick={() => {}}
        isOpen={this.state.showSearch}
        onDismiss={() => {
          this.setState({
            showSearch: false,
            searchUsers: [],
            searchString: "",
          });
        }}
        closeButtonAriaLabel="Close"
        headerText="Search User"
        type={1}
        onOpened={() => {}}
      >
        <div style={{ marginTop: "20px" }}>
          <SearchBox
            placeholder="Search"
            onSearch={async (newValue) => {
              this.setState({ searchUserLoading: true });
              var us_query = new HomeQueryParams()
                .WithSiteId(this.state.siteId)
                .WithSearch(newValue)
                .WithNumberOfDays(1)
                .WithParam("startDate", new Date(new Date().toDateString()));

              if (currentUser.teams && currentUser.teams.length > 0)
                us_query.WithTeamId(currentUser.teams[0].id);

              var userSummary = await HomeClient.SearchUsersPlans(us_query);

              var data = userSummary.data;

              console.log({ search: data });

              this.setState({
                searchUsers: data,
                searchUserLoading: false,
                searchString: newValue,
              });
            }}
          />
          {/* <ComboBox
            label="Search Users"
            options={this.state.searchableUsers}
            // styles={comboBoxStyles}
            onChange={async (event, option, index, value) => {
              this.setState({ searchUserLoading: true });
              const { key, text } = option;

              var us_query = new HomeQueryParams()
                .WithSiteId(this.state.siteId)
                .WithUserId(key)
                .WithNumberOfDays(1)
                .WithParam("startDate", new Date(new Date().toDateString()));

              if (currentUser.teams && currentUser.teams.length > 0)
                us_query.WithTeamId(currentUser.teams[0].id);

              var userSummary = await HomeClient.DayView(us_query);

              var data = userSummary.data[0]?.users[0]?.myDay;

              if (data) {
                this.setState({
                  searchUsers: data,
                  : key,
                  searchUserLoading: false,
                });
              } else {
                this.setState({
                  searchUsers: data,
                  : key,
                  searchUserLoading: false,
                });
              }
            }}
            allowFreeInput
            autoComplete="on"
          /> */}

          {this.state.searchUserLoading ? (
            <div style={{ width: "100%", display: "flex" }}>
              <div style={{ width: "100%", margin: "20px 0px" }}>
                <Shimmer style={{ padding: "5px" }} />
                <Shimmer style={{ padding: "5px" }} width="90%" />
                <Shimmer style={{ padding: "5px" }} width="80%" />
                <Shimmer style={{ padding: "5px" }} width="70%" />
                <Shimmer style={{ padding: "5px" }} width="60%" />
                <Shimmer style={{ padding: "5px" }} width="50%" />
                <Shimmer style={{ padding: "5px" }} width="40%" />
              </div>
            </div>
          ) : (
            <>
              {this.state.searchUsers.length <= 0 &&
                this.state.searchString !== "" && (
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "50px",
                      color: "#616161",
                    }}
                  >
                    <div>
                      <NoUserLogo
                        width="60"
                        height="60"
                        stroke="#616161"
                        fill="#616161"
                        style={{ overflow: "visible" }}
                      />
                    </div>
                    <p>No User Found</p>
                  </div>
                )}

              {this.state.searchUsers &&
                this.state.searchUsers.length > 0 &&
                this.state.searchUsers.map((item) => {
                  var user = item.users[0].user;
                  var myDay = item.users[0]?.myDay;
                  return (
                    <div
                      className={this.classNames.itemCell + " hover"}
                      style={{
                        backgroundColor: "white",
                        borderRadius: "15px",
                        marginTop: "10px",
                        cursor: "default",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "13px",
                          fontWeight: "bold",
                          marginBottom: "3px",
                        }}
                      >
                        {user.name} {user.surname}
                      </div>
                      <div
                        style={{
                          color: "gray",
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "3px",
                        }}
                      >
                        <EmailLogo height="15" width="15" />
                        <div style={{ marginLeft: "5px" }}>{user.email}</div>
                      </div>

                      <div
                        style={{
                          color: "gray",
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "3px",
                        }}
                      >
                        <PhoneLogo height="15" width="15" />
                        <div style={{ marginLeft: "5px" }}>
                          {user.mobile ? user.mobile : "-"}
                        </div>
                      </div>

                      <div
                        style={{
                          color: "gray",
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "3px",
                        }}
                      >
                        <PinLogo height="15" width="15" />
                        {myDay?.parts[0].state === "workFromOffice" && (
                          <div style={{ marginLeft: "5px" }}>
                            {myDay?.parts[0].site?.name}
                          </div>
                        )}
                        {myDay?.parts[0].state === "notWorking" && (
                          <div style={{ marginLeft: "5px" }}>
                            {myDay?.parts[0].notWorkingReason?.name}
                          </div>
                        )}
                        {myDay?.parts[0].state === "workFromHome" && (
                          <div style={{ marginLeft: "5px" }}>Home</div>
                        )}
                        {!myDay?.parts[0].state && (
                          <div style={{ marginLeft: "5px" }}>Unknown</div>
                        )}
                      </div>

                      {myDay?.checkIns && myDay?.checkIns.length > 0 && (
                        <div
                          style={{
                            fontSize: "13px",
                            fontWeight: "bold",
                            marginBottom: "3px",
                          }}
                        >
                          Assets
                        </div>
                      )}
                      {myDay?.checkIns &&
                        myDay?.checkIns.length > 0 &&
                        myDay?.checkIns.map((checkIn) => (
                          <div
                            style={{
                              color: "gray",
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "3px",
                            }}
                          >
                            <CheckInLogo height="15" width="15" />
                            <div style={{ marginLeft: "5px", display: "flex" }}>
                              <div>{checkIn.asset?.assetType?.name}</div>:{" "}
                              <div style={{ marginLeft: "5px" }}>
                                {checkIn.asset?.name}
                              </div>
                              <div style={{ marginLeft: "5px" }}>
                                (
                                <Moment utc local format="h:mm A">
                                  {moment(checkIn.checkInDate)}
                                </Moment>
                                )
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  );
                })}
            </>
          )}
        </div>
      </Panel>
    );
  }

  filterUsers() {
    if (this.state.siteId && this.state.selectedAttribute.users.length > 0) {

      var filtered = _.filter(this.state.selectedAttribute.users, (user) => {
        return (
          user?.site?.id === this.state.siteId
        );
      });

      var sorted = _.sortBy(filtered, [
        function (user) {
          return user?.myDay ? -1 : 1;
        },
      ]);

        this.setState({ filteredUsers: sorted });

    } else if (
      this.state.siteId &&
      this.state.selectedAttribute.users.length <= 0
    ) {
      this.setState({ filteredUsers: [] });
    }
  }

  getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  async getUserAttributes() {
    this.setState({ attributesLoading: true });
    var query = new UserAttributesQueryParams()
      .WithIsMenu(true)
      .WithGetUsers(true);

    var response = await UserAttributesClient.List(query);

    var data = response.data?.data;

    if (
      this.state.selectedAttribute &&
      this.state.selectedAttribute.users.length
    ) {
      var selected = _.find(data, (o) => {
        return o.id === this.state.selectedAttribute.id;
      });

      if (selected)
        this.setState({
          selectedAttribute: selected,
          userAttributes: data,
          attributesLoading: false,
        });
    } else this.setState({ userAttributes: data, attributesLoading: false });
  }

  async getSearchableUsers() {
    var users = await UsersClient.LookUp(
      new UserQueryParams()
        .Sort("name", "asc")
        .Paginate(0, 9999)
        .WithSearchableOnly(true)
    );

    if (users.successful && users.data.length > 0) {
      var mapped = users.data.map((user) => ({
        key: user.id,
        text: `${user.name} ${user.surname}`,
      }));

      this.setState({ searchableUsers: mapped });
    }
  }

  async populateData() {
    this.setState({ loading: true });
    var currentUser = await UsersClient.Get("current");

    this.getUserAttributes();
    this.getSearchableUsers();

    var accountSettings = await AccountSettingsClient.GetCurrent();

    if (accountSettings.data) {
      setAccountTheme(accountSettings.data);
    }

    if (currentUser.successful) {
      var siteId = currentUser.data?.site?.id;
      var site = currentUser.data?.site;

      if (!siteId) {
        var sites = await SitesClient.List(
          new SiteQueryParams().WithStatus("active").WithExpand(false)
        );
        this.setState({
          sites: sites.data.data,
        });
        siteId = sites.data.data[0]?.id;
        site = sites.data.data[0];
      }
      this.setState({
        siteId: siteId,
        userId: currentUser.data.id,
        user: currentUser.data,
        selectedSite: site,
      });

      if (currentUser.data.teams && currentUser.data.teams.length > 0) {
        var primary = currentUser.data.teamMembers.filter(
          (team) => team.isPrimary == true
        )[0];

        if (primary) {
          this.setState({
            primaryTeam: {
              id: primary.team.id,
              name: primary.team.name,
            },
            teams: currentUser.data.teams,
          });
        }

        if (!this.state.selectedTeam) {
          this.setState({
            selectedTeam: {
              id: primary.team.id,
              name: primary.team.name,
            },
          });
        }

        var teamId = this.state.selectedTeam
          ? this.state.selectedTeam.id
          : primary
          ? primary.team.id
          : currentUser.data.teams[0].id;

        //Get currentUser MyDay
        var userSummary = await HomeClient.DayView(
          new HomeQueryParams()
            .WithSiteId(siteId)
            .WithTeamId(teamId)
            .WithUserId(currentUser.data.id)
            .WithNumberOfDays(7)
            .WithParam("startDate", this.state.currentWeekStartDate)
        );

        var slicedDate = userSummary.data.slice(0, 5);
        var slicedDateWeekends = userSummary.data.slice(5, 7);
        this.setState({
          userSummary: slicedDate,
          userSummaryWeekends: slicedDateWeekends,
          myDay: this.getMyDayForDate(userSummary.data, this.state.currentDate),
          initComplete: true,
          loading: false,
        });
      } else {
        this.setState({ loading: false });
      }
    } else {
      this.setState({ loading: false });
    }
  }

  forwardOneWeek() {
    var newDate = addDays(this.state.currentDate, 7);
    var newWeekStartDate = this.getMonday(newDate);

    if (
      newWeekStartDate.getTime() < new Date().getTime() &&
      this.state.currentWeekStartDate.getTime() > new Date().getTime()
    ) {
      this.setState(
        {
          currentDate: new Date(new Date().toDateString()),
          currentWeekStartDate: newWeekStartDate,
          refreshPlanner: !this.state.refreshPlanner,
        },
        () => this.populateData()
      ); //If current week, default to current
    } else {
      this.setState(
        {
          currentDate: newWeekStartDate,
          currentWeekStartDate: newWeekStartDate,
          refreshPlanner: !this.state.refreshPlanner,
        },
        () => this.populateData()
      ); //If other week, default to Monday
    }
  }

  backwardOneWeek() {
    var newDate = addDays(this.state.currentDate, -7);
    var newWeekStartDate = this.getMonday(newDate);

    if (
      newWeekStartDate.getTime() < new Date().getTime() &&
      this.state.currentWeekStartDate.getTime() > new Date().getTime()
    ) {
      this.setState(
        {
          currentDate: new Date(new Date().toDateString()),
          currentWeekStartDate: newWeekStartDate,
          refreshPlanner: !this.state.refreshPlanner,
        },
        () => this.populateData()
      ); //If current week, default to current
    } else {
      this.setState(
        {
          currentDate: newWeekStartDate,
          currentWeekStartDate: newWeekStartDate,
          refreshPlanner: !this.state.refreshPlanner,
        },
        () => this.populateData()
      ); //If other week, default to Monday
    }
  }

  forwardOneDay() {
    var newDate = addDays(this.state.currentDate, 1);
    var newWeekStartDate = this.getMonday(newDate);

    this.setState(
      {
        currentDate: newDate,
        currentWeekStartDate: newWeekStartDate,
        refreshPlanner: !this.state.refreshPlanner,
      },
      () => this.populateData()
    );
  }

  backwardOneDay() {
    var newDate = addDays(this.state.currentDate, -1);
    var newWeekStartDate = this.getMonday(newDate);

    this.setState(
      {
        currentDate: newDate,
        currentWeekStartDate: newWeekStartDate,
        refreshPlanner: !this.state.refreshPlanner,
      },
      () => this.populateData()
    );
  }

  getMyDayForDate(data, date) {
    var myDay = {
      date: this.state.currentDate,
      status: "planned",
      parts: [
        {
          state: "workFromOffice",
        },
      ],
    };

    if (data && data.length > 0) {
      for (var i = 0; i < data.length; i++) {
        if (new Date(data[i].date).getTime() == date.getTime()) {
          if (data[i].users.length > 0 && data[i].users[0].myDay) {
            myDay = data[i].users[0].myDay;
          }
        }
      }
    }

    return myDay;
  }

  async populateAssets(softLoad = false) {
    const { date } = this.props;
    const { currentDate, neighborhoodId, attributeId } = this.state;

    if (!softLoad) this.setState({ loading: true });

    const currentUser = this.state.user;

    if (!currentUser) return;

    var us_query = new HomeQueryParams()
      .WithUserId(currentUser.id)
      .WithNumberOfDays(7)
      .WithParam("startDate", this.state.currentWeekStartDate);

    if (this.state.siteId) us_query.WithSiteId(this.state.siteId);

    if (currentUser.teams && currentUser.teams.length > 0)
      us_query.WithTeamId(currentUser.teams[0].id);

    var userSummary = await HomeClient.DayView(us_query);

    var slicedDate = userSummary.data.slice(0, 5);
    var allDates = slicedDate.map((x) => x.date);

    var tz = moment.tz.guess();
    const start = moment.tz(currentDate, tz); // original timezone

    const local = start.tz(tz).format("YYYY-MM-DD[T]HH:mm:ss.SSS");

    var withTimeUtc = moment(local).utc().format("YYYY-MM-DD[T]HH:mm:ss.SSS");
    var end = moment(withTimeUtc)
      .add(24, "h")
      .subtract(1, "s")
      .format("YYYY-MM-DD[T]HH:mm:ss.SSS");

    var query = new AssetsQueryParams();

    query.WithStatus("active");

    query.WithStart(withTimeUtc);
    query.WithEnd(end);

    query.Paginate(0, 2000);

    if (this.state.selectedAssetType?.id)
      query.WithAssetTypeId(this.state.selectedAssetType?.id);

    if (neighborhoodId) query.WithNeighborhoodId(neighborhoodId);
    if (attributeId) query.WithAttributeIds([attributeId]);

    if (this.state.floor?.id) query.WithFloorId(this.state.floor?.id);

    var response = await AssetsClient.List(query);

    if (response.data) {
      const { data } = response.data;

      if (data.length > 0) {
        var divideByFour = Math.ceil(data.length / 4);
        var splittedData = _.chunk(data, divideByFour);

        this.setState({
          assets: data,
          column1: splittedData[0],
          column2: splittedData[1],
          column3: splittedData[2],
          column4: splittedData[3],
          fullAssetsList: data,
          loading: false,
          allDates: allDates,
        });
      } else {
        this.setState({
          assets: [],
          fullAssetsList: [],
          column1: [],
          column2: [],
          column3: [],
          column4: [],
          loading: false,
          allDates: allDates,
        });
      }
    }
    // }
    this.updateAssetTypes();
    this.setState({ loading: false, assetsInitialized: true });
  }

  async updateAssetTypes() {
    if (this.state.floor?.id) {
      var assetTypesResponse = await FloorsClient.GetAssetTypesFromFloor(
        this.state.floor?.id
      );

      var assetTypes = assetTypesResponse.data;

      this.setState({
        assetTypes: assetTypes,
        floorAssetTypes: assetTypes,
      });
    }
  }
}
